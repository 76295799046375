<div id="profilePage">
  <div id="profileBody">
    <form [formGroup]="profileForm">
      <div class="row" style="margin-top: 10px!important; margin-left: 10px!important;">
        <!-- <button class="btn btn-primary" style="padding-top: 0px; padding-bottom: 0px;" mat-raised-button [routerLink]="['/profile/home']">
                  <mat-icon>keyboard_arrow_left</mat-icon> BACK
              </button>     -->
        <mat-icon>keyboard_arrow_left</mat-icon> <span class="align-self-center" id="backBtn" style="cursor:pointer;"
          (click)="handleBackButton()"> BACK </span>
      </div>
      <div id="contentWrapper">
        <div class="row" style="padding-top: 10px;">
          <div class="col-12 page-title-layout" style="padding:0px">
            <h1 style=" float: left;"> {{pageTitle}} </h1>
          </div>
          <div class="col-12 p-0 pb-4 save-btn">
            <button type="button" class="button primary" id="saveWeightings" (click)="saveProfile('open')">
              SAVE PROFILE
            </button>
            <button type="submit" class="button secondary" style="float: right;" id="saveAndClose"
              (click)="saveProfile('close')">
              SAVE AND CLOSE
            </button>
          </div>
        </div>
        <div class="profile">
          <div class="profile-info">
            <lag-text class="w-100" [field]="createProfileMetadata.externalProfileName" [hideRequiredMarker]="true"></lag-text>
            <div class="w-100 internal-name">
              <lag-text class="w-100" [field]="createProfileMetadata.internalProfileName" [readonly]="isInternalNameReadOnly" [hideRequiredMarker]="true"></lag-text>
              <mat-checkbox #sameAsInternal (change)="onInternalNameCheckboxChange($event)" class="form-control" formControlName="sameAsInternalName"> Same as external name
              </mat-checkbox>
            </div>
            <lag-text-multiple-autocomplete [showSelectAll]="false" class="matFormField w-100 client-name" [field]="createProfileMetadata.client" hideRequiredMarker></lag-text-multiple-autocomplete>
          </div>  
          <div class="row profile-group" style="padding-top: 10px;">
            <div class="col-5 pl-0 pr-0" style="display: inline;">
              <div class="row profile-group-module">
                <h5 class="col-3 p-0 text-left">Link to module </h5>
                <mat-checkbox class="col-4 form-control" formControlName="linkToBenchmark"
                style="border: 0px; padding: 0px!important; display: inline;"> Benchmarking </mat-checkbox>
                <mat-checkbox class="col-3 form-control" formControlName="linkToMultiSite"
                  style="border: 0px; padding: 0px!important; display: inline;"> Multi-Site </mat-checkbox>
                <mat-checkbox class="col-2 form-control" formControlName="linkToGeoskill"
                  style="border: 0px; padding: 0px!important; display: inline;"> Geoskill </mat-checkbox>                
              </div>
            </div>
            <div class="col-5">
              <div class="row profile-group-countries">
                <h5 class="col-5 text-center">Select applicable countries</h5>
                <mat-checkbox [checked]="true" (change)="onContryChange('USA', $event)" class="col-4 form-control"
                style="border: 0px; padding: 0px!important; display: inline;"> United States </mat-checkbox>
                <mat-checkbox [disabled]="true" (change)="onContryChange('Canada', $event)" class="col-3 form-control"
                  style="border: 0px; padding: 0px!important; display: inline;"> Canada </mat-checkbox>
              </div>
            </div>
            <div class="col-2 profile-group-active">
              <span> Active/Inactive </span>
              <mat-slide-toggle style="float: right;" class="example-margin" [color]="color" formControlName="isActive"
                [checked]="checked" [disabled]="disabled">
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row indicator" style="padding-top: 10px; padding-bottom: 20px;">
          <mat-tab-group mat-stretch-tabs dynamicHeight class="profileTabs">
            <mat-tab>
              <div class="mt-2" style="text-align: left;">
                <div class="row">
                  <div class="row w-100 pb-2 justify-content-end">
                    <div class="m-0 p-0  d-flex justify-content-end">
                      <button mat-icon-button (click)="addfirstLevelNode()" class="new-indicator">
                        <mat-icon class="green-add-icon">add</mat-icon>
                        <span>ADD NEW INDICATOR</span>
                      </button>
                    </div>
                  </div>

                  <mat-tree class="indicator-tree" [dataSource]="dataSource" [treeControl]="treeControl" style="width: 100%;" cdkDropList
                    (cdkDropListDropped)="drop($event)">
                    <mat-tree-node id="treeNode1" *matTreeNodeDef="let node;" matTreeNodePadding cdkDrag
                      [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()"
                      (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">
                      <!-- <button mat-icon-button></button>    -->

                      <button *ngIf="!node.expandable" mat-icon-button></button>
                      <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item" (click)="setExpansionModel(node.nodeId)">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>

                      <div class="row w-100" [ngStyle]="styleTreeNode(node)">
                        <div class="col-2 d-flex">
                          <mat-icon class="dragIndicatory mr-4 align-self-center">drag_indicator</mat-icon>
                          <mat-form-field appearance="outline" style="width: 60%;">
                            <input class="text-right" (change)="setItemWeight(node,leafItemWeight.value)"
                              #leafItemWeight matInput type="text" [value]="getWeight(node.weight)" autocomplete="off">
                            <div matSuffix style="position: relative; bottom: 4px;"> %</div>
                          </mat-form-field>
                        </div>

                        <div class="col-2 align-self-center text-center"> {{node.effectiveWeight | number}}% </div>

                        <div class="col-5 align-self-center text-left">

                          <!-- <mat-form-field class="w-100"  appearance="outline">
                                            <mat-select (openedChange)="onOpen(node, $event)" [(value)]="node.name" >
                                              <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh;">
                                                    <mat-option *cdkVirtualFor="let indicatorNode of node.dropdownOptions"
                                                    (onSelectionChange)="onDataPointSelection(node,indicatorNode)"
                                                     [value]="indicatorNode.indicatorName"> 
                                                      {{indicatorNode.indicatorName}}
                                                  </mat-option>                                                   
                                                </cdk-virtual-scroll-viewport>
                                            </mat-select>
                                          </mat-form-field>    -->
                          <mat-form-field class="w-100" appearance="outline">
                            <mat-select (openedChange)="onOpen(node, $event)" [(value)]="node.name" disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input #search autocomplete="off" aria-label="Search" matInput
                                      (keyup)="filterOptions(node, $event)" (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearFilter(node); search.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.filteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>

                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.filteredOptions"
                                  (onSelectionChange)="onDataPointSelection(node,indicatorNode, $event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="col-3 align-self-center text-right">
                          <button *ngIf="node.level !=3 && node.isLeafNode == false" mat-icon-button
                            (click)="addNewItem(node)">
                            <mat-icon class="green-add-icon">add</mat-icon>
                          </button>
                          <button mat-icon-button (click)="deleteNode(node)" style="float: right;">
                            <mat-icon class="green-icon material-icons-outlined">delete</mat-icon>
                          </button>
                        </div>
                      </div>

                    </mat-tree-node>

                    <mat-tree-node id="treeNode2" *matTreeNodeDef="let node; when: hasSupplyGroup" matTreeNodePadding
                      cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()"
                      (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">

                      <button *ngIf="!node.expandable" mat-icon-button></button>
                      <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item" (click)="setExpansionModel(node.nodeId)">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>

                      <div class="row w-100" [ngStyle]="styleTreeNode(node)">
                        <div class="col-2 d-flex">
                          <mat-icon class="dragIndicatory mr-4 align-self-center">drag_indicator</mat-icon>
                          <mat-form-field appearance="outline" style="width: 60%; float: right; ">
                            <input class="text-right" (change)="setItemWeight(node,leafItemWeight.value)"
                              #leafItemWeight matInput type="text" [value]="getWeight(node.weight)" autocomplete="off">
                            <div matSuffix style="position: relative; bottom: 4px;"> %</div>
                          </mat-form-field>
                        </div>
                        <div class="col-2 align-self-center text-center"> {{node.effectiveWeight | number}}% </div>

                        <div class="col-6 align-self-center text-left">

                          <!-- <mat-form-field style="width: 35%;" appearance="outline" [ngStyle]="styleGroupCustomName(node)" > -->
                          <mat-form-field style="width: 35%;" appearance="outline">
                            <input (change)="setGroupName(node,groupCustomName.value)" #groupCustomName matInput
                              type="text" autocomplete="off" placeholder="Enter group name"
                              [value]="node.groupCustomName">
                          </mat-form-field>

                          <mat-form-field style="width: 60%;" appearance="outline">
                            <mat-select (openedChange)="onOpen(node, $event,parentNodeId)" [(value)]="node.name"
                              disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input matInput #search autocomplete="off" aria-label="Search"
                                      (keyup)="filterOptions(node, $event)" (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearFilter(node); search.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.filteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>
                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.filteredOptions"
                                  (onSelectionChange)="addSupplyGroupItems(node,indicatorNode,$event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>



                        </div>
                        <div class="col-2 align-self-center text-right">
                          <button mat-icon-button (click)="addSupplyGroupItem(node)">
                            <mat-icon class="green-add-icon">add</mat-icon>
                          </button>

                          <button mat-icon-button (click)="deleteNode(node)" style="float: right;">
                            <mat-icon class="green-icon">delete</mat-icon>
                          </button>
                        </div>
                      </div>

                    </mat-tree-node>


                    <mat-tree-node id="treeNode7" *matTreeNodeDef="let node; when: hasOccupationData" matTreeNodePadding
                      cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()"
                      (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">

                      <button *ngIf="!node.expandable" mat-icon-button></button>
                      <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item" (click)="setExpansionModel(node.nodeId)">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>

                      <div class="row w-100" [ngStyle]="styleTreeNode(node)">
                        <div class="col-2 d-flex">
                          <mat-icon class="dragIndicatory mr-4 align-self-center">drag_indicator</mat-icon>
                          <mat-form-field appearance="outline" style="width: 60%; float: right; ">
                            <input class="text-right" (change)="setItemWeight(node,leafItemWeight.value)"
                              #leafItemWeight matInput type="text" [value]="getWeight(node.weight)" autocomplete="off">
                            <div matSuffix style="position: relative; bottom: 4px;"> %</div>
                          </mat-form-field>
                        </div>
                        <div class="col-2 align-self-center text-center"> {{node.effectiveWeight | number}}% </div>

                        <div class="col-6 align-self-center text-left">

                          <mat-form-field style="width: 60%;" appearance="outline">
                            <mat-select (openedChange)="onOpen(node, $event,parentNodeId)" [(value)]="node.name"
                              disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input matInput #search autocomplete="off" aria-label="Search"
                                      (keyup)="filterOptions(node, $event)" (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearFilter(node); search.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.filteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>
                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.filteredOptions"
                                  (onSelectionChange)="onDataPointSelection(node,indicatorNode,$event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field style="width: 35%;" appearance="outline">
                            <mat-select [(value)]="node.secondName" disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input matInput #search2 autocomplete="off" aria-label="Search"
                                      (keyup)="filterSecondDropdownOptions(node, $event)"
                                      (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search2.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearSecondFilter(node); search2.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search2.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.secondDropdownFilteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>
                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.secondDropdownFilteredOptions"
                                  (onSelectionChange)="onOccupationDiversitySelection(node,indicatorNode,$event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-2 align-self-center text-right">
                          <button mat-icon-button (click)="deleteNode(node)" style="float: right;">
                            <mat-icon class="green-icon">delete</mat-icon>
                          </button>
                        </div>
                      </div>

                    </mat-tree-node>


                    <mat-tree-node id="treeNode3" *matTreeNodeDef="let node; when: hasCostGroup" matTreeNodePadding
                      cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()"
                      (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">

                      <button *ngIf="!node.expandable" mat-icon-button></button>
                      <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item" (click)="setExpansionModel(node.nodeId)">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>

                      <div class="row w-100" [ngStyle]="styleTreeNode(node)">
                        <div class="col-2 d-flex">
                          <mat-icon class="dragIndicatory mr-4 align-self-center">drag_indicator</mat-icon>
                          <mat-form-field appearance="outline" style="width: 60%; float: right; ">
                            <input class="text-right" (change)="setItemWeight(node,leafItemWeight.value)"
                              #leafItemWeight matInput type="text" [value]="getWeight(node.weight)" autocomplete="off">
                            <div matSuffix style="position: relative; bottom: 4px;"> %</div>
                          </mat-form-field>
                        </div>
                        <div class="col-2 align-self-center text-center"> {{node.effectiveWeight | number}}% </div>


                        <div class="col-6 align-self-center text-left">

                          <mat-form-field style="width: 35%;" appearance="outline">
                            <input (change)="setGroupName(node,groupCustomName.value)" #groupCustomName matInput
                              type="text" autocomplete="off" placeholder="Enter group name"
                              [value]="node.groupCustomName">
                          </mat-form-field>

                          <mat-form-field style="width: 60%;" appearance="outline">
                            <mat-select (openedChange)="onOpen(node, $event)" [(value)]="node.name" disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input matInput #search autocomplete="off" aria-label="Search"
                                      (keyup)="filterOptions(node, $event)" (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearFilter(node); search.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.filteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>


                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.filteredOptions"
                                  (onSelectionChange)="addCostGroupItems(node,indicatorNode,$event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>

                        </div>
                        <div class="col-2 align-self-center text-right">
                          <button mat-icon-button (click)="addCostGroupItem(node)">
                            <mat-icon class="green-add-icon">add</mat-icon>
                          </button>

                          <button mat-icon-button (click)="deleteNode(node)" style="float: right;">
                            <mat-icon class="green-icon">delete</mat-icon>
                          </button>
                        </div>
                      </div>

                    </mat-tree-node>

                    <mat-tree-node id="treeNode4" *matTreeNodeDef="let node; when: hasDegreeCompletions"
                      matTreeNodePadding cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)"
                      (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">

                      <button *ngIf="!node.expandable" mat-icon-button></button>
                      <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item" (click)="setExpansionModel(node.nodeId)">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>

                      <div class="row w-100" [ngStyle]="styleTreeNode(node)">
                        <div class="col-2 d-flex">
                          <mat-icon class="dragIndicatory mr-4 align-self-center">drag_indicator</mat-icon>
                          <mat-form-field appearance="outline" style="width: 60%; float: right; ">
                            <input class="text-right" (change)="setItemWeight(node,leafItemWeight.value)"
                              #leafItemWeight matInput type="text" [value]="getWeight(node.weight)" autocomplete="off">
                            <div matSuffix style="position: relative; bottom: 4px;"> %</div>
                          </mat-form-field>
                        </div>
                        <div class="col-2 align-self-center text-center"> {{node.effectiveWeight | number}}% </div>


                        <div class="col-7 align-self-center text-left">

                          <mat-form-field style="width: 60%;" appearance="outline">
                            <mat-select (openedChange)="onDegreeDrodownOpen(node, $event)" [(value)]="node.name"
                              disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input matInput #searchDegree autocomplete="off" aria-label="searchDegree"
                                      (keyup)="filterDegreeOptions(node, $event)" (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="searchDegree.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearDegreesFilter(node); searchDegree.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="searchDegree.value===''" matSuffix
                                      mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>
                                <mat-option *ngFor="let degree of node.filteredDegrees"
                                  [ngStyle]="styleDegreeOptions(degree)" [value]="degree.name"
                                  (onSelectionChange)="onDegreeSelection(node,degree, $event)">
                                  {{degree.name}}
                                </mat-option>
                              </div>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field style="width: 35%;" appearance="outline">
                            <mat-select [(value)]="node.secondName" disableRipple>
                              <div class="select-container">
                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.secondDropdownOptions"
                                  (onSelectionChange)="onDegreeLevelSelection(node,indicatorNode,$event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>
                              </div>
                            </mat-select>
                          </mat-form-field>

                        </div>

                        <div class="col-1 align-self-center text-right">
                          <button mat-icon-button (click)="deleteNode(node)" style="float: right;">
                            <mat-icon class="green-icon">delete</mat-icon>
                          </button>
                        </div>
                      </div>

                    </mat-tree-node>

                    <mat-tree-node id="treeNode5" *matTreeNodeDef="let node; when: hasSkillsGroup" matTreeNodePadding
                      cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()"
                      (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">

                      <button *ngIf="!node.expandable" mat-icon-button></button>
                      <button *ngIf="node.expandable" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item" (click)="setExpansionModel(node.nodeId)">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>

                      <div class="row w-100" [ngStyle]="styleTreeNode(node)">
                        <div class="col-2 d-flex">
                          <mat-icon class="dragIndicatory mr-4 align-self-center">drag_indicator</mat-icon>
                          <mat-form-field appearance="outline" style="width: 60%; float: right; ">
                            <input class="text-right" (change)="setItemWeight(node,leafItemWeight.value)"
                              #leafItemWeight matInput type="text" [value]="getWeight(node.weight)" autocomplete="off">
                            <div matSuffix style="position: relative; bottom: 4px;"> %</div>
                          </mat-form-field>
                        </div>
                        <div class="col-2 align-self-center text-center"> {{node.effectiveWeight | number}}% </div>


                        <div class="col-6 align-self-center text-left">
                          <mat-form-field style="width: 60%;" appearance="outline">
                            <mat-select (openedChange)="onOpen(node, $event)" [(value)]="node.name" disableRipple
                              disableOptionCentering>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input #search autocomplete="off" aria-label="Search" matInput
                                      (keyup)="filterOptions(node, $event)" (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearFilter(node); search.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.filteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>


                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.filteredOptions"
                                  (onSelectionChange)="onDataPointSelection(node,indicatorNode, $event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>



                          <mat-form-field *ngIf="node.indicatorType === 'LinkedIn'" style="width: 35%;"
                            appearance="outline">
                            <mat-select [(value)]="node.secondName" disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input #search2 autocomplete="off" aria-label="search2" matInput
                                      (keyup)="filterSecondDropdownOptions(node, $event)"
                                      (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search2.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearSecondFilter(node); search2.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search2.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.filteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>


                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.secondDropdownFilteredOptions"
                                  (onSelectionChange)="onLinkedInSkillSelection(node,indicatorNode,$event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>



                          <!-- <mat-form-field *ngIf="node.indicatorType == 'LinkedIn'" style="width: 35%;" appearance="outline">
                            <mat-select [(value)]="node.secondName" disableRipple>
                              <div class="select-container">
                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.secondDropdownOptions"
                                  (onSelectionChange)="onDegreeLevelSelection(node,indicatorNode,$event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>
                              </div>
                            </mat-select>
                          </mat-form-field> -->

                        </div>

                        <div class="col-2 align-self-center text-right">
                          <button
                            *ngIf="node.name === 'Turnover Rate' || node.name === 'Occupation Diversity Density' || node.name === 'Occupation Diversity Count'"
                            mat-icon-button (click)="addNewItem(node)">
                            <mat-icon class="green-add-icon">add</mat-icon>
                          </button>
                          <button mat-icon-button (click)="deleteNode(node)" style="float: right;">
                            <mat-icon class="green-icon">delete</mat-icon>
                          </button>
                        </div>
                      </div>

                    </mat-tree-node>

                    <mat-tree-node id="treeNode6" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding cdkDrag
                      [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()"
                      (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">
                      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item"
                        (click)="setExpansionModel(node.nodeId)">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>

                      <div class="row w-100" [ngStyle]="styleTreeNode(node)">
                        <div class="col-2 d-flex">
                          <mat-icon class="dragIndicatory mr-4 align-self-center">drag_indicator</mat-icon>
                          <mat-form-field appearance="outline" style="width: 60%; float: right; ">
                            <input class="text-right" (change)="setItemWeight(node,parentItemWeight.value)"
                              #parentItemWeight matInput type="text" [value]="getWeight(node.weight)"
                              autocomplete="off">
                            <div matSuffix style="position: relative; bottom: 4px;"> %</div>
                          </mat-form-field>
                        </div>

                        <!-- <div class="col-2 align-self-center text-center "> {{node.effectiveWeight}}%  ({{node.expandable}}) (hasChild) </div> -->
                        <div class="col-2 align-self-center text-center "> {{node.effectiveWeight | number}}% </div>


                        <div class="col-5 align-self-center text-left">


                          <mat-form-field class="w-100" appearance="outline">
                            <mat-select (openedChange)="onOpen(node, $event)" [(value)]="node.name" disableRipple>
                              <div class="select-container">
                                <mat-optgroup>
                                  <mat-form-field style="width: 95%;" appearance="outline">
                                    <input #search autocomplete="off" aria-label="Search" matInput
                                      (keyup)="filterOptions(node, $event)" (keydown)="$event.stopPropagation()">
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                      aria-label="Clear" (click)="clearFilter(node); search.value = ''">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <button [disableRipple]="true" *ngIf="search.value===''" matSuffix mat-icon-button>
                                      <mat-icon>search</mat-icon>
                                    </button>
                                  </mat-form-field>
                                </mat-optgroup>

                                <mat-optgroup *ngIf="node.filteredOptions.length == 0">
                                  <div>data point not found!</div>
                                </mat-optgroup>


                                <mat-option [value]="indicatorNode.indicatorName"
                                  *ngFor="let indicatorNode of node.filteredOptions"
                                  (onSelectionChange)="onDataPointSelection(node,indicatorNode, $event)">
                                  {{ indicatorNode.indicatorName }}
                                </mat-option>

                              </div>
                            </mat-select>
                          </mat-form-field>


                          <!-- <mat-form-field class="w-100" appearance="outline">
                            <mat-select (openedChange)="onOpen(node, $event)" [(value)]="node.name" disableRipple>
                              <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh;">
                                <mat-option *cdkVirtualFor="let indicatorNode of node.filteredOptions"
                                  (onSelectionChange)="onDataPointSelection(node,indicatorNode,$event)"
                                  [value]="indicatorNode.indicatorName">
                                  {{indicatorNode.indicatorName}}
                                </mat-option>
                              </cdk-virtual-scroll-viewport>
                            </mat-select>
                          </mat-form-field> -->


                        </div>

                        <div class="col-3 align-self-center text-right">
                          <button mat-icon-button (click)="addNewItem(node)">
                            <mat-icon class="green-add-icon">add</mat-icon>
                          </button>
                          <button mat-icon-button (click)="deleteNode(node)" style="float: right;">
                            <mat-icon class="green-icon">delete</mat-icon>
                          </button>
                        </div>

                      </div>

                    </mat-tree-node>

                  </mat-tree>


                </div>
              </div>
            </mat-tab>
            <!-- <mat-tab label="MANUAL SCORE ASSIGNMENT" [disabled]="true">
              <div>
                MANUAL SCORE ASSIGNMENT
              </div>
            </mat-tab>
            <mat-tab label="USER & MARKET ASSIGNMENT" [disabled]="true">
              <div>
                USER & MARKET ASSIGNMENT
              </div>
            </mat-tab> -->
          </mat-tab-group>
        </div>
      </div>
    </form>
  </div>
</div>
